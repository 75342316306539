import Head from "next/head";
import logo from "../public/images/logo-1.png"
import Script from "next/script";

export default function Document() {
  return (
    <Head>
      <meta charset="utf-8" />
      <link
        rel="stylesheet"
        href="https://unpkg.com/placeholder-loading/dist/css/placeholder-loading.min.css"
      />
      <link
        rel="stylesheet"
        href="https://unpkg.com/placeholder-loading/dist/css/placeholder-loading.min.css"
      />

      <link
        rel="preload"
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap"
        as="style"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap"
        media="print"
        onload="this.media='all'"
      />
      <link
        rel="stylesheet"
        href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
        integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
        crossOrigin="anonymous"
      />
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-F3w7mX95PdgyTmZZMECAngseQB83DfGTowi0iMjiWaeVhAn4FJkqJByhZMI3AhiU"
        crossorigin="anonymous"
      />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <Script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-/bQdsTh/da6pkI1MST/rWKFNjaCP5gBSY4sEBT38Q/9RBh9AH40zEOg7Hlq2THRZ"
        crossorigin="anonymous"
      ></Script>
      <Script src="https://www.google.com/recaptcha/api.js"></Script>
      <Script
        src={process.env.NEXT_PUBLIC_FRESHWORK}
        chat={process.env.NEXT_PUBLIC_CHAT_BOT}>
      </Script>

      <Script
        id="tagmanager-main"
        strategy="afterInteractive"
        async
        src={`https://www.googletagmanager.com/gtag/js?id='AW-10904704145'`}
      ></Script>
      <Script
        id="tagmanager-setup"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-10904704145');
          `,
        }}
      />




      <Script async src="https://www.googletagmanager.com/gtag/js?id=G-DSYX4LT048"></Script>
    </Head>
  )
}