import Link from 'next/link'; 
import { useRouter } from 'next/router'; 
import styles from './Breadcrumbs.module.css'; 
import { isMobile } from 'react-device-detect'; 
import { useSelector } from 'react-redux'; 
import { useEffect, useState } from 'react'; 

const Breadcrumbs = () => {
  const [brandName, setBrandName] = useState(""); 
  const [dealerBrandName, setDealerBrandName] = useState(""); 
  const [dealerCity, setDealerCity] = useState(""); 
  const router = useRouter(); 
  const pathArray = router.asPath.split('/').filter(path => path); 

  const { vehicleDetails } = useSelector((state) => state.vehicle); 
  const { dealerDetails } = useSelector((state) => state.dealer); 


  const getProductBrand = () => {
    return vehicleDetails?.companyData ? vehicleDetails?.companyData.title : '';
  };

  const getDealerBrand = () => {
    return dealerDetails?.Company ? dealerDetails?.Company[0]?.toLowerCase() : '';
  };

  const fetchDealerCity = () => {
    return dealerDetails?.cityData ? dealerDetails?.cityData[0]?.name : '';
  };

  let productBrand = '';
  if (pathArray[0] === 'product' && pathArray.length === 2) {
    productBrand = getProductBrand(); 
    if (productBrand) {
      pathArray.splice(1, 0, productBrand); 
    }
  }

  let dealerBrand = '';
  let city = '';
  if (pathArray[0] === 'dealer-profile' && pathArray.length === 2) {
    dealerBrand = getDealerBrand(); 
    if (dealerBrand) {
      pathArray.splice(1, 0, dealerBrand); 
    }

    city = fetchDealerCity(); 
    if (city) {
      pathArray.splice(2, 0, city); 
    }

    pathArray[0] = 'dealer-showrooms'; 
  }


  useEffect(() => {
    if (vehicleDetails && vehicleDetails.companyData) {
      setBrandName(vehicleDetails?.companyData?.slug); 
    }
    if (dealerDetails && dealerDetails.Company) {
      setDealerBrandName(dealerDetails?.Company[0]?.toLowerCase()); 
    }
    if (dealerDetails && dealerDetails.cityData) {
      setDealerCity(dealerDetails?.cityData[0]?.name); 
    }
  }, [vehicleDetails, dealerDetails]);

  return (
    <nav aria-label="breadcrumb" className={`${isMobile ? 'fs-10' : ''} container`}>
      <ol className={`${styles['breadcrumb-ol']} ${styles[isMobile ? 'breadcrumb-container-mob' : 'breadcrumb-container']}`}>
        {pathArray.length > 0 && (
          <li className={styles[isMobile ? 'home-breadcrum-mob' : 'home-breadcrum']}>
            <Link href="/">Home</Link> 
          </li>
        )}
        {pathArray.map((path, index) => {
          {/* console.log(pathArray, "pathArray"); */}
          const href = "/" + pathArray.slice(0, index + 1).join("/"); 
          const isLast = index === pathArray.length - 1; 

          let linkHref = href;

          if (path === productBrand) {
            linkHref = `/electric-vehicle-companies/${brandName}`; 
          } else if (path === "product") {
            linkHref = "/electric-two-wheelers"; 
          } else if (path === dealerBrand) {
            linkHref = `/dealer-showrooms/${encodeURIComponent(
              dealerBrandName.replace(/\s+/g, "-")
            )}`;
          } else if (path === city) {
            linkHref = `/dealer-showrooms/${encodeURIComponent(
              dealerBrandName.replace(/\s+/g, "-")
            )}/${encodeURIComponent(dealerCity.replace(/\s+/g, "-"))}`;
          }

          {/* console.log(linkHref, "linkHref"); */}

          return (
            <li
              key={href}
              className={
                styles[isMobile ? "breadcrumb-li-mob" : "breadcrumb-li"]
              }
            >
              {isLast ? (
                <span>{path.replace(/-/g, " ")}</span> 
              ) : (
                <Link href={linkHref}>{path.replace(/-/g, " ")}</Link> 
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs; 